@import '~@core/styles/mixins/dark-mode';

%container {
  margin: 1em auto;
  width: calc(var(--container) - var(--hub-toc-width));
  max-width: 100%;
}

.SuggestionsList {
  @extend %container;

  &-header {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &-title {
    gap: inherit;
    display: inline-flex;
    margin-right: auto;
  }

  &-list {
    padding: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid var(--color-border-default);

    & > li {
      display: flex;
      justify-content: space-between;
      padding: var(--md);
      gap: 0.33em;

      & + li {
        border-top: 1px solid;
        border-color: inherit;
      }

      :global(.DateLine) {
        margin-left: auto;
      }
    }
  }

  &-nullcase {
    color: var(--gray50);
    text-align: center;
    margin: 6em 0;
    font-size: 1.5em;

    &_error {
      color: var(--red50);
    }

    :global {
      .IconWrapper,
      .Spinner {
        font-size: 1.5em;
      }
    }
  }
}

.SuggestionDiff {
  @extend %container;

  &_loading {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 0.3em;
    margin-right: auto;

    a[href='/suggested-edits'] {
      line-height: 0;
    }
  }

  &-nav {
    margin: 0.5em 0 1.5em;
    background: var(--color-bg-page);
    position: sticky;
    top: -1px;
    z-index: 9;
    padding: 0.5em 0;
    border: 0 solid var(--color-border-default);
    border-width: 1px 0;
  }

  a[disabled] {
    pointer-events: none;

    > :global(.IconWrapper) {
      opacity: 0.25;
    }
  }

  &-changes {
    &_before {
      :global(.diff-add) {
        display: none;
      }
    }

    &_after {
      :global(.diff-remove) {
        display: none;
      }
    }

    pre {
      > code {
        max-height: unset;
      }

      @include dark-mode {
        background: var(--gray0);
      }
    }
  }

  :global(.DateLine) {
    margin: 1em 0;
  }
}

.SuggestionDiff-changes {
  :global(.diff-add) {
    background: var(--green90);
  }

  :global(.diff-remove) {
    background: var(--red100);
  }

  @include dark-mode {
    :global(.diff-add) {
      background: var(--green20);
    }

    :global(.diff-remove) {
      background: var(--red10);
    }
  }
}
